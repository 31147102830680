import React from "react"

import SEO from "../components/Seo"

const NotFoundPage = () => (
  <>
    <SEO title="Test" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
  </>
)

export default NotFoundPage
